import { Link } from "react-router-dom";

export default function Footer(){

    return(
        <>
        
        <Block1 />
     
        </>

    );

}

function Block1(){
    return(
<>

<footer id="footer" className="footer">

<div className="footer-legal text-center">
  <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">

    <div className="d-flex flex-column align-items-center align-items-lg-start">
      <div className="copyright">
        &copy; Copyright <strong><span>Ulrich Sanga</span></strong>.All Rights Reserved
      </div>
      <div className="credits">

      </div>
    </div>

    <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
      <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
      <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
      <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
      <a href="#" className="google-plus"><i className="bi bi-skype"></i></a>
      <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
    </div>

  </div>
</div>

</footer>
   
</>
      
    );
}


