import { Link } from "react-router-dom";
import Header  from './Header';

export default function Réalisations(){

    return(
        <>
        <Header/>
        <Block1 />
     
        </>

    );

}

function Block1(){
    return(
<>
<section id="portfolio" className="portfolio" data-aos="fade-up">

<div className="container">

  <div className="section-header">
    
  </div>

</div>

<div className="container-fluid" data-aos="fade-up" data-aos-delay="200">

  <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order">

    <ul className="portfolio-flters">
      <li data-filter="*" className="filter-active">Tout</li>
      <li data-filter=".filter-app">Web</li>
      <li data-filter=".filter-product">Mobile</li>
      <li data-filter=".filter-branding">IA</li>
      <li data-filter=".filter-books1">IOT</li>
      <li data-filter=".filter-books">Web3D</li>
      <li data-filter=".filter-books">Blockchain</li>
      <li data-filter=".filter-books">Personnel</li>
      
      </ul>
    <div className="row g-0 portfolio-container">

      <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
        <img src="assets/img/portfolio/app-1.jpg" className="img-fluid" alt=""/>
        <div className="portfolio-info">
          <h4>TMV COMMUNITY</h4>
          <a href="assets/img/portfolio/app-1.jpg" title="App 1" data-gallery="portfolio-gallery" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
          <a href="portfolio-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></a>
        </div>
      </div>
      
      <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
        <img src="assets/img/portfolio/jeune.jpg" className="img-fluid" alt=""/>
        <div className="portfolio-info">
          <h4>TMV SOLIDAIRE</h4>
          <a href="assets/img/portfolio/books-1.jpg" title="Branding 1" data-gallery="portfolio-gallery" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
          <a href="portfolio-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></a>
        </div>
      </div>
      
      <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
        <img src="assets/img/portfolio/jkl.jpg" className="img-fluid" alt=""/>
        <div className="portfolio-info">
          <h4>TMV GROUP</h4>
          <a href="assets/img/portfolio/branding-1.jpg" title="Branding 1" data-gallery="portfolio-gallery" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
          <a href="portfolio-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></a>
        </div>
      </div>

      <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-product">
        <img src="assets/img/portfolio/product-1.jpg" className="img-fluid" alt=""/>
        <div className="portfolio-info">
          <h4>APP CLM RéCAP+ </h4>
          <a href="assets/img/portfolio/product-1.jpg" title="Product 1" data-gallery="portfolio-gallery" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
          <a href="portfolio-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></a>
        </div>
      </div>
      <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
        <img src="assets/img/portfolio/5kap.png" className="img-fluid" alt=""/>
        <div className="portfolio-info">
          <h4>5NKAP COMPTABLE</h4>
          <a href="assets/img/portfolio/5kap.png" title="App 1" data-gallery="portfolio-gallery" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
          <a href="portfolio-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></a>
        </div>
      </div>
      
      <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
        <img src="assets/img/portfolio/app-2.jpg" className="img-fluid" alt=""/>
        <div className="portfolio-info">
          <h4>INNOVE-VIZ</h4>
          <a href="assets/img/portfolio/app-2.jpg" title="Branding 1" data-gallery="portfolio-gallery" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
          <a href="portfolio-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></a>
        </div>
      </div>
      
      <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-app">
        <img src="assets/img/portfolio/visua.jpg" className="img-fluid" alt=""/>
        <div className="portfolio-info">
          <h4>360° THINK-VIZ</h4>
          <a href="assets/img/portfolio/visua.jpg" title="Branding 1" data-gallery="portfolio-gallery" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
          <a href="portfolio-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></a>
        </div>
      </div>

      <div className="col-xl-3 col-lg-4 col-md-6 portfolio-item filter-product">
        <img src="assets/img/portfolio/auto.jpg" className="img-fluid" alt=""/>
        <div className="portfolio-info">
          <h4>SGO Parc Automobile</h4>
          <a href="assets/img/portfolio/auto.jpg" title="Product 1" data-gallery="portfolio-gallery" className="glightbox preview-link"><i className="bi bi-zoom-in"></i></a>
          <a href="portfolio-details.html" title="More Details" className="details-link"><i className="bi bi-link-45deg"></i></a>
        </div>
      </div>




    </div>
  </div>

</div>
<br/>
<div className="but"> <Link to={"/Contact"}><a href="" className="btn-get-started color-white">Me contacter</a></Link>
</div>
</section>
</>
      
    );
}


