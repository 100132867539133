import { Link } from "react-router-dom";
import Header from './Header';

export default function Compétence(){

    return(
        <>
        <Header />
        
        <Block1 />
     
        </>

    );

}

function Block1(){
    return(
<>

<section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          
        </div>

        <div class="row gy-5">

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-1.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

               <Link to={"/Back-end"}><a href="#" class="stretched-link">
                  <h3>DEV BACK-END</h3>
                </a>
                </Link> 
                <p>Riche d’une forte expérience sur l’écosystème .NET (.NET CORE 6 , 7 et .NET FRAMEWORK) ainsi que sur C# ,F# ,C++,Python et Django.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="300">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-2.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">
              <Link to={"/Front-End"}>
                <a href="" class="stretched-link">

                  <h3>DEV FRONT-END</h3>
                </a>
                </Link>
                <p>Mon expérience en Frontend se concentre principalement sur React et React native mais il m'arrive aussi d'utiliser vue et angular.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-3.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/Test"}><a href="#" class="stretched-link">
                  <h3>TESTEUR</h3>
                </a>
                </Link>
                <p>TestGrid , xUnit , SonarQube , TDD , ODD répresentent le socle sur lequel je m'appuis afin de livrer des applicatons de qualité .</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="500">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-4.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">
              <Link to={"/-Devops"}> <a href="" class="stretched-link">
                  <h3>DEVOPS</h3>
                </a>
               </Link>
                <p>Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident adipisci neque.</p>
                
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-5.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Cloud"}><a href="" class="stretched-link">
                  <h3>CLOUD</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="700">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-6.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Architecte"}>     <a href="" class="stretched-link">
                  <h3>ACHITECTE</h3>
                </a>
                </Link>
                <p>Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim.</p>
               
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-7.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Gestion-De-Projet"}> <a href="" class="stretched-link">
                  <h3>GESTION DE PROJET</h3>
                </a>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
              </Link>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-8.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Analyse-Fonctionnelle"}>   <a href="" class="stretched-link">
                  <h3>ANALYSE FONCTIONNEL</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                
              </div>

            </div>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-9.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Optimisation"}>   <a href="" class="stretched-link">
                  <h3>OPTIMISATION</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
               
              </div>
              
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-13.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Apprentissage-automatique"}><a href="" class="stretched-link">
                  <h3>MACHING LEARNING</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                
              </div>
              
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-14.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Web3D"}><a href="" class="stretched-link">
                  <h3>WEB 3D</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
               
              </div>
              
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-15.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">
             
              <Link to={"/-Datavisualisation"}><a href="" class="stretched-link">
                  <h3>DATA VISUALISATION</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
             
              </div>
              
            </div>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-10.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Innovation"}> <a href="" class="stretched-link">
                  <h3>INNOVATION</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
               
              </div>
              
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-11.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Recherche-opérationnelle"}><a href="" class="stretched-link">
                  <h3>RECHERCHE OPERATIONNEL</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                
              </div>
              
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-12.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Système-complexe"}> <a href="" class="stretched-link">
                  <h3>SYTSEME COMPLEXE</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
             
              </div>
              
            </div>
          </div>
          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-18.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-IOT"}>   <a href="" class="stretched-link">
                  <h3>IOT</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                
              </div>
              
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-17.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

                <Link to={"/-Blockchain"}><a href="" class="stretched-link">
                  <h3>BLOCKCHAIN</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
               
              </div>
              
            </div>
          </div>

          <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="600">
            <div class="service-item">
              <div class="img">
                <img src="assets/img/services-16.jpg" class="img-fluid"al="" />
              </div>
              <div class="details position-relative">

              <Link to={"/-Modélisation"}><a href="" class="stretched-link">
                  <h3>MODELISATION</h3>
                </a>
                </Link>
                <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
              
              </div>
              
            </div>
          </div>




        </div>

      </div>
      <br/>
      <div className="but"> <Link to={"/Réalisations"}><a href="" className="btn-get-started color-white">Mes réalisations</a></Link>
 </div> 
    </section>
   
</>
      
    );
}


