
import { Link } from "react-router-dom";
import Header  from './Header';


export default function Moi(){

  return(
<>
<Header />
<Block1 />
</>



  )

}


function Block1(){

    return(
        <>
        
        <section id="about" className="about"  data-aos="fade-up">
      <div className="container" data-aos="fade-up">



        <div className="row g-4 g-lg-5" data-aos="fade-up" data-aos-delay="200">

          <div className="col-lg-5">
            
            
            <div className="about-img">
              <img src="assets/img/sanga.jpg" className="img-fluid" alt="" />
              
            </div>
            
          </div>

          <div className="col-lg-7">
            <h3 className="pt-0 pt-lg-5">FULL-STACK .NET (C#) DEVELOPER</h3>
            <p>Ulrich Sanga est un ingénieur logiciel qui travaille depuis près de quatre ans dans l’industrie du logiciel, FULL-STACK .NET (TECH LEAD) et architecte technique pour le compte de SECY SOFT SARL une société camerounaise qui développe des applications dans le secteur de la finance, santé et transport. Concrètement, son travail consiste à produire des applications reposant sur les technologies .NET , React.js, Node.js et Python suivant les exigences de la société en matière de performance, de sécurité et de qualité tout en restant proche du cahier des charges du client. Il est obsédé par l’innovation et les méthodes d’ingénierie qui permettent de faire la différence, c’est ainsi qu’il s’appuie sur trois piliers pour travailler de manière efficace. Le premier pilier, c’est sa formation universitaire titulaire d’un baccalauréat en informatique de l’université de Yaoundé I avec une spécialisation en système d’information et génie logiciel, Le deuxième pilier, c’est son équipe, il croit profondément en l’intelligence collective, la collaboration orientée solution et la diversité des compétences d’une équipe, Le troisième pilier, c’est son ADN technologique qui se concentre principalement sur la résolution inventive des problèmes avec la méthode TRIZ.</p>

            
            <ul className="nav nav-pills mb-3">
              <li><a className="nav-link active" data-bs-toggle="pill" href="#tab1">FORMATIONS</a></li>
              <li><a className="nav-link" data-bs-toggle="pill" href="#tab2">EXPÉRIENCES </a></li>
            
            </ul>

          
            <div className="tab-content">

              <div className="tab-pane fade show active" id="tab1">

                <div className="d-flex align-items-center mt-4">
                  <i className="bi bi-check2"></i>
                  <h4>BACCALAUREAT(LICENCE) EN INFORMATIQUE </h4>
                </div>
                <p></p>
                <p>Diplôme obtenu au sein de l'université de Yaoundé I avec une spécialisation en système d'information et génie logiciel.</p>

                <div className="d-flex align-items-center mt-4">
                  <i className="bi bi-check2"></i>
                  <h4>STAGE DE FIN D’ETUDE DAF UNIVERSITE DE YAOUNDEI</h4>
                </div>
                <p></p>
                <p>Stage de fin d'étude avec mémoire réalisé au
sein de la division Administrative et financière de la faculté des sciences de
l’université de Yaoundé I.Le
Sujet portait sur La Conception et Implémentation d’une Plateforme de Visualisation des
données (Cas du traitement des fichiers du personnel et du traitement des
documents Financiers de la Faculté des Sciences de l’Université de Yaoundé I ) </p>

                <div className="d-flex align-items-center mt-4">
                  <i className="bi bi-check2"></i>
                  <h4>DIPLOME D'ETUDES COLLEGIALES(BACC ENSEIGNEMENT SECONDAIRE)</h4>
                </div>
                <p></p>
                <p>Diplôme de l'enseignement secodaire Général avec pour spécialité Mathématiques et Sciences Physiques obtenu au sein du Lycée Billingue de Nkol-éton à Yaoundé au cameroun.</p>

              </div>

              <div className="tab-pane fade show" id="tab2">

               
                <div className="d-flex align-items-center mt-4">
                  <i className="bi bi-check2"></i>
                  <h4>TECH LEAD .NET</h4> - Septembre 2023- Aujoud'hui</div><h4>SECY SOFT SARL,Yaoundé.</h4>
                  <p></p>
                <p>En tant que DEVELOPPEUR FULL-STACK .NET, je joue le rôle Tech Lead .Net au sein de mon équipe, rôle essentiel dans la
direction technique de nos projets.je travaille en étroite collaboration avec les autres développeurs, les architectes logiciels et
les parties prenantes afin de concevoir et de livrer des solutions logicielles de haute qualité. l’expérience client priorité absolu.</p>

<div className="d-flex align-items-center mt-4">
                  <i className="bi bi-check2"></i>
                  <h4>FULL-STACK .NET DEVELOPER</h4> - Sept 2020-Sept 2023</div><h4>SECY SOFT SARL,  Yaoundé.</h4>
                <p></p>
                <p>Ma mission au sein de l’équipe est d’analyser le besoin et rédiger des spécifications fonctionnelles, développer des applications
web , mobile et de bureau avec la pile .NET et REACT, effectuer les tests et la rédaction des supports techniques de l’application
avec c#. Enfin, participer à la correction des dysfonctionnements éventuels des supports réalisés. Ma responsabilité s’étant
jusqu’a la mise en place du cahier d’architecture technique et l’écriture des recettes de tests.</p>


<div className="d-flex align-items-center mt-4">
                  <i className="bi bi-check2"></i>
                  <h4>CONSULTANT INFORMATIQUE</h4> - Janvier 2023- Aujoud'hui</div><h4>MUTUELLE TMV , Yaoundé.</h4>
                <p></p>
                <p>Mon rôle de consultant en temps partiel est de participer a la mise en place d’une plateforme(www.tmvsolidaire.com et
tinvescogroup.com ) qui vas permettre de mettre en relation des associations du secteur de l’économie social et solidaire au
niveau mondiale.</p>
<div className="d-flex align-items-center mt-4">
                  <i className="bi bi-check2"></i>
                  <h4>CONSULTANT INFORMATIQUE</h4>-Novembre 2023 </div><h4>ONG RECAP+ , Yaoundé.</h4>
                <p></p>
                <p>Mission de conseil en temps partiel de dix jours auprès de l’ONG RECAP+ pour mise a jour de la plateforme web et
mobile (CLM RECAP+) de dénonciation des frais de payement de prise en charges des personnelles vulnérables.</p>
                
<div className="d-flex align-items-center mt-4">
                  <i className="bi bi-check2"></i>
                  <h4>CONSULTANT INFORMATIQUE</h4> - Février 2021 - Février 2022</div><h4>GPS SARL , Yaoundé.</h4>
                <p></p>
                <p>Mon rôle de consultant en temps partiel est de participer a la mise en place d’une plateforme(www.tmvsolidaire.com et
tinvescogroup.com ) qui vas permettre de mettre en relation des associations du secteur de l’économie social et solidaire au
niveau mondiale.</p>

              </div>

            </div>

            

          </div>

        </div>

      </div>
      
      
      
  <div className="but"> <Link to={"/Valeurs"}><a href="" className="btn-get-started color-white">Mes valeurs</a></Link>
 </div> 
 </section>


        </>

    );

}

