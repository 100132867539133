import { Link } from "react-router-dom";
import Header from "./Header";
export default function Test(){
  return(


<>

<Header />
<Block1 />
</>

  )
  }

function Block1(){

    return(
      <>
      <section id="hero" className="hero carousel  carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">


      <div className="carousel-inner">
        <div className="carousel-item active ">
          <div className="container">
            <div className="row justify-content-center gy-6">

              
  <center><h2>Compétences en Test de logiciels</h2></center> 
  <br/>
 <br/>
 <div className="row">

<div  data-aos="fade-up" data-aos-delay="200">
  <div className="box"> <p><span>En tant que professionnel du test de logiciels spécialisé sur l'écosystème .NET, je possède une expertise approfondie dans la conception et l'exécution de tests pour les applications développées avec des technologies que C#, ASP.NET, et .NET Core. Je possède une solide expérience dans l'élaboration de tests unitaires à l'aide de MSTest, NUnit ou xUnit, ainsi que dans la mise en place de tests d'intégration pour garantir le bon fonctionnement des composants logiciels. Je maîtrise les outils de test automatisé spécifiques à .NET j’utilise par exemple Visual Studio Test Explorer pour automatiser les tests afin d’assurer la qualité et la fiabilité des applications .NET. Je suis capable d’utiliser des bibliothèques comme Moq pour la simulation de comportements dans les tests unitaires. Ma compréhension des bonnes pratiques de développement comme TDD et des principes SOLID me permet d'assurer la qualité du code tout au long du processus de développement, en intégrant des tests dès les premières étapes du cycle de vie du logiciel.</span></p>
  </div>
</div>
</div>
       
              
  
  <Comp/>

            </div>
          </div>
        </div>
  

  
        <div className="carousel-item">
          <div className="container">
            <div className="row justify-content-center gy-6">
  
              <div className="col-lg-5 col-md-8">
                <img src="assets/img/hero-carousel/hero-carousel-3.svg" alt="" className="img-fluid img" />
              </div>
  
              <div className="col-lg-9 text-center">
                <h2>Temporibus autem quibusdam</h2>
                <p>Beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt omnis iste natus error sit voluptatem accusantium.</p>
                <a href="#featured-services" className="btn-get-started scrollto ">Get Started</a>
              </div>
  
            </div>
          </div>
        </div>
      </div>
  
  
    </section>
    </>
    );

}

function Comp(){
  return(

    <>
        <section id="features" className="features">
      <div className="container" data-aos="fade-up">

        <ul className="nav nav-tabs row gy-4 d-flex">

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link active show" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>xUNIT</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>CLI .NET</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>TDD</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>SOLID</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>NUnit</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>MSTest</h4>
            </a>
          </li>
          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link  show" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>Test Intégration</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>Test Unitaire</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>Tests Acceptation</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>SonarQube</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>Mavean</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>Smoke Tests</h4>
            </a>
          </li>

        </ul>

        <div className="tab-content">

          <div className="tab-pane active show" id="tab-1">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">

                <ul>
                  
                  <li><i className="bi bi-check-circle-fill"></i>Je suis chargé de concevoir les cas de test pour les fonctionnalités développées en utilisant des outils tels que Visual Studio Test Explorer ou XUnit.</li>
                  <li><i className="bi bi-check-circle-fill"></i>J’utilise fréquemment NUnit, MSTest ou xUnit pour exécuter des suites de tests automatisés afin de valider le bon fonctionnement des applications développées en .NET.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Je suis chargé d’analyser les résultats des tests automatisés pour détecter et signaler les éventuels problèmes ou anomalies dans le fonctionnement des applications, en travaillant en étroite collaboration avec les  autres développeurs.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Je suis chargé de mettre en place et exécuté des tests de charge et de performance pour évaluer les performances des applications sous différentes conditions d'utilisation.</li>
                  <li><i className="bi bi-check-circle-fill"></i>je suis chargé de rédiger les rapports détaillés sur les résultats des tests effectués, de suivre l'évolution des anomalies détectées et participer activement à la résolution de ces dernières avec l'équipe de développement.</li>

                </ul>
                <p>
                En somme Mon rôle est ici est d'assurer la qualité et la fiabilité des applications développées  en réalisant des tests approfondis, en identifiant les problèmes potentiels et en contribuant à la résolution efficace des anomalies détectées.
                </p>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                <img src="assets/img/features-1.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-2">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">

                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Je suis chargé de concevoir les cas de test pour les fonctionnalités développées en utilisant des outils tels que Visual Studio Test Explorer ou XUnit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> J’utilise fréquemment NUnit, MSTest ou xUnit pour exécuter des suites de tests automatisés afin de valider le bon fonctionnement des applications développées en .NET.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-2.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-3">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Pariatur</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.</li>
                </ul>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-3.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-4">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Nostrum</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-4.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-5">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Adipiscing</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-5.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-6">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Reprehit</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-6.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </>
  )
}

