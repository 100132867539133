import { Link } from "react-router-dom";
import Header from "./Header";
export default function Frontend(){
  return(


<>

<Header />
<Block1 />
</>

  )
  }

function Block1(){

    return(
      <>
      <section id="hero" className="hero carousel  carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">


      <div className="carousel-inner">
        <div className="carousel-item active ">
          <div className="container">
            <div className="row justify-content-center gy-6">

              
  <center><h2>Compétences Front-End</h2></center> 

  <div className="row">

<div  data-aos="fade-up" data-aos-delay="200">
  <div className="box"> <p><span>En tant que développeur front-end passionné et polyvalent, je maîtrise l'art de créer des interfaces utilisateur modernes et intuitives en utilisant des technologies telles que React, React Native et Vue. Avec React, je conçois des applications web interactives et dynamiques, en exploitant la puissance du JSX et des composants réutilisables pour offrir une expérience utilisateur fluide et engageante. Mon expertise dans la gestion de l'état avec Redux ou Context API me permet de maintenir des applications complexes tout en garantissant une performance optimale. Grâce à React Native, je développe des applications mobiles multiplateformes avec une base de code commune, offrant ainsi une expérience utilisateur native sur iOS et Android. Ma connaissance des bonnes pratiques de développement mobile me permet de concevoir des applications réactives et performantes, tout en respectant les normes de l'industrie. Avec Vue.js, j'apporte une approche élégante au développement front-end, en créant des interfaces utilisateur modulaires et réactives. Ma capacité à utiliser efficacement les directives, les composants et les mixins de Vue me permet de construire des applications web évolutives tout en maintenant un code propre et bien organisé.</span></p>
  </div>
</div>
</div>
  
  <Comp/>

            </div>
          </div>
        </div>
  

  
        <div className="carousel-item">
          <div className="container">
            <div className="row justify-content-center gy-6">
  
              <div className="col-lg-5 col-md-8">
                <img src="assets/img/hero-carousel/hero-carousel-3.svg" alt="" className="img-fluid img" />
              </div>
  
              <div className="col-lg-9 text-center">
                <h2>Temporibus autem quibusdam</h2>
                <p>Beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt omnis iste natus error sit voluptatem accusantium.</p>
                <a href="#featured-services" className="btn-get-started scrollto ">Get Started</a>
              </div>
  
            </div>
          </div>
        </div>
      </div>
  
  
    </section>
    </>
    );

}

function Comp(){
  return(

    <>
        <section id="features" className="features">
      <div className="container" data-aos="fade-up">

        <ul className="nav nav-tabs row gy-4 d-flex">

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link active show" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>React js</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>Angular</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>Vue Js</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>HTML</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>CSS</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>JavaScript</h4>
            </a>
          </li>
          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link  show" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>Blazor</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>Razor</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>Bootscrap</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>TypeScript</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>UI</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab">
              <i className="bi bi-exclude"></i>
              <h4>UX</h4>
            </a>
          </li>

        </ul>

        <div className="tab-content">

          <div className="tab-pane active show" id="tab-1">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">

              <h3>Mes taches récurentes.</h3>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Je conçois et je mets en œuvre des composants d'interface utilisateur réutilisables en utilisant les bibliothèques et Framework appropriés pour chaque plateforme (React pour le web, React Native pour les applications mobiles, Vue.js également pour le web).</li>
                  <li><i className="bi bi-check-circle-fill"></i> Je collabore avec les autres développeurs back-end pour intégrer les interfaces utilisateur avec les API et les services web, en veillant à une communication efficace entre le front-end et le back-end.</li>
                  <li><i className="bi bi-check-circle-fill"></i>J’utilise fréquemment des bibliothèques telles que Redux, MobX ou Vuex pour gérer l'état global de l'application et assurer une gestion efficace des données et des interactions utilisateur. </li>
                  <li><i className="bi bi-check-circle-fill"></i>Je mets constamment en place des méthodes pour optimiser les performances des applications ce qui garantit la rapidité de chargement, à la réactivité de l'interface utilisateur et à l'efficacité des rendus.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Je suis également responsable d’assurer que les interfaces utilisateur sont adaptées à une variété d'appareils et de tailles d'écran, en utilisant des techniques de conception responsive ou adaptive</li>
                  </ul>
                <p>
                
                En résumé mes compétences front-end spécialisé dans React, React Native et Vue  me permettent de créer des expériences utilisateur modernes, performantes et réactives pour les applications web et mobile, en travaillant étroitement avec les équipes  et en respectant les bonnes pratiques de développement front-end. 
                </p>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                <img src="assets/img/features-1.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-2">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">

                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-2.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-3">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Pariatur</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.</li>
                </ul>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-3.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-4">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Nostrum</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-4.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-5">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Adipiscing</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-5.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-6">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Reprehit</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-6.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </>
  )
}

