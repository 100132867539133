

import Header from './Header';
import Banner from './Banner';
import Moi from './Moi';
import Footer from './Footer';

function App(){
  return (
    <>
    <Header />
    <Banner />



    </> 
    
  );
}

export default App;
