import { Link } from "react-router-dom";

export default function Header(){

    return(
        <>
        
        <Block1 />
     
        </>

    );

}

function Block1(){
    return(
<>
<header id="header" className="header fixed-top" data-scrollto-offset="0">
    <div className="container-fluid d-flex align-items-center justify-content-between">

      <a href="" className="logo d-flex align-items-center scrollto me-auto me-lg-0">
        <img src="assets/img/logo.png" alt="" /> 
        <h1><span><Link to={"/"}>Ulrich Sanga</Link></span></h1>
      </a>
      <br />
      <br />
      <nav id="navbar" className="navbar">
        <ul>
        <li><a className="" href=""><Link to={"/Qui_Suis_Je"}>Présentation</Link></a></li>
        <li><a className="nav-link scrollto" href=""><Link to={"/Valeurs"}>Valeurs</Link></a></li>
          <li><a className="nav-link scrollto" href=""><Link to={"/Compétences"}>Compétences</Link> </a></li>
          <li><a className="nav-link scrollto" href=""><Link to={"/Réalisations"}>Réalisations</Link></a></li>
          <li><a className="nav-link scrollto" href=""><Link to={"/Contact"}>Contact</Link></a></li>
          <li><a className="nav-link scrollto" href="">Blog</a></li>
        

        </ul>
        <a className="btn-getstarted scrollto" href="">MON CV</a>
      

      
  <i className="bi bi-list mobile-nav-toggle d-none"></i>
  
    </nav>
    
    </div>

  </header>
  
   
</>
      
    );
}


