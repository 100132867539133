import { Link } from "react-router-dom";
import Header from "./Header";
export default function Compétences(){
  return(


<>

<Header />
<Block1 />
</>

  )
  }

function Block1(){

    return(

      <>
      <section id="hero" className="hero carousel  carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">


      <div className="carousel-inner">
        <div className="carousel-item active ">
          <div className="container">
            <div className="row justify-content-center gy-6">

              
  <center><h2>Compétences Back-End.</h2></center> 

 <br/>
 <br/>
 <div className="row">

<div  data-aos="fade-up" data-aos-delay="200">
  <div className="box"> <p><span>En tant que développeur back-end expérimenté en C#, je maîtrise la conception et l'implémentation de services web et d'applications serveur robustes. Mes compétences s'étendent à la manipulation avancée de bases de données relationnelles et non relationnelles, ainsi qu'à l'intégration de systèmes complexes à l'aide de technologies telles que ASP.NET, Entity Framework et SQL Server. Je suis également capable de concevoir des architectures logicielles évolutives, en mettant l'accent sur la modularité, la sécurité et les performances Ma connaissance approfondie des principes SOLID, du développement orienté objet et des bonnes pratiques de programmation me permet de produire un code fiable, maintenable et extensible.
De plus, je suis à l'aise avec les technologies émergentes telles que .NET Core, Web API et Azure, ce qui me permet d'apporter des solutions innovantes et adaptées aux besoins actuels du développement back-end.

En somme , mes compétences techniques en développement back-end avec C# me permettent de relever les défis les plus complexes en matière d'architecture logicielle, de performances et d'intégration système</span></p>
  </div>
</div>
</div>
          
      




  
  <Comp/>

            </div>
          </div>
        </div>
  

  
        <div className="carousel-item">
          <div className="container">
            <div className="row justify-content-center gy-6">
  
              <div className="col-lg-5 col-md-8">
                <img src="assets/img/hero-carousel/hero-carousel-3.svg" alt="" className="img-fluid img" />
              </div>
  
              <div className="col-lg-9 text-center">
                <h2>Temporibus autem quibusdam</h2>
                <p>Beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt omnis iste natus error sit voluptatem accusantium.</p>
                <a href="#featured-services" className="btn-get-started scrollto ">Get Started</a>
              </div>
  
            </div>
          </div>
        </div>
      </div>
  
  
    </section>
    </>
    );

}

function Comp(){
  return(

    <>
        <section id="features" className="features">
      <div className="container" data-aos="fade-up">

        <ul className="nav nav-tabs row gy-4 d-flex">

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link active show" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>C#</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>.NET CORE</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>.NET FRAMWORK</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>.NET ASPIRE</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>F#</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>AZURE</h4>
            </a>
          </li>
          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link  show" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>APIs</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>MLN.NET</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>SQL SERVER</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>C++</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>PYTHON</h4>
            </a>
          </li>

          <li className="nav-item col-6 col-md-4 col-lg-2">
            <a className="nav-link" data-bs-toggle="tab" >
              <i className="bi bi-exclude"></i>
              <h4>DJANGO</h4>
            </a>
          </li>

        </ul>
       



        <div className="tab-content">

          <div className="tab-pane active show" id="tab-1">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
              <h3>Connaissances Techniques</h3>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i>J’ai une connaissance approfondie de C#, le langage de programmation principal utilisé dans l'écosystème .NET.</li>
                  <li><i className="bi bi-check-circle-fill"></i>j’ai une Compréhension approfondie du framework .NET, y compris .NET Framework et .NET Core/.NET 5+, ainsi que de leurs fonctionnalités spécifiques.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Je possède une solide expérience dans le développement d'applications Web en utilisant ASP.NET MVC ou ASP.NET Web API pour créer des services web RESTful.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Je possède des compétences dans l'utilisation de SQL Server comme système de gestion de base de données (SGBD) et/ou dans l'utilisation de Framework ORM  tels qu'Entity Framework Core ou NHibernate.</li>
                  <li><i className="bi bi-check-circle-fill"></i>je maîtrise de l'utilisation d'Entity Framework pour la manipulation des données, y compris la création de modèles de données, les requêtes LINQ et la gestion des migrations de base de données.</li>
                  <li><i className="bi bi-check-circle-fill"></i>je maîtrise quelques systèmes de contrôle de version pour gérer efficacement le code source et collaborer avec d'autres développeurs.</li>
                  <li><i className="bi bi-check-circle-fill"></i>j’applique les principes SOLID régulièrement pour concevoir des systèmes flexibles et évolutifs.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Avec TDD, je suis capable d’écrire des tests unitaires avant même d'écrire le code de production, ce qui permet de garantir la qualité du code et la facilité de maintenance.</li>
                </ul>
                <p>
                En somme en tant que développeur back-end expérimenté, j'ai acquis une solide expertise dans les langages C#, .NET, C++ et Python, ainsi que dans la conception et la gestion de bases de données SQL Server. J'ai travaillé sur des projets impliquant le développement d'API RESTful, l'implémentation des principes TDD (Test-Driven Development) et SOLID pour garantir des solutions logicielles robustes et évolutives. Ma capacité à concevoir, développer et maintenir des systèmes back-end performants et fiables a été démontrée à travers mon expérience professionnelle, où j'ai contribué au déploiement réussi de plusieurs applications critiques.
                </p>
<br/>
<h3>Mes taches récurentes.</h3>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i>Utilisation d'Entity Framework pour la gestion des données, y compris la création de modèles de données, les migrations de base de données et les requêtes LINQ.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Manipulation directe de la base de données à l'aide de SQL Server et ADO.NET.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Implémentation de mécanismes d'authentification et d'autorisation à l'aide d'Identity Framework.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Utilisation de tokens JWT pour sécuriser les services Web.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Intégration avec des services tiers via des API RESTful ou SOAP.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Consommation de services Web externes à l'aide de bibliothèques telles que HttpClient.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Utilisation de techniques de mise en cache pour réduire les temps de réponse et minimiser la charge du serveur.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Écriture de tests unitaires pour valider le fonctionnement du code.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Intégration des tests unitaires dans des pipelines d'intégration continue (CI) à l'aide d'outils tels que Jenkins, TeamCity ou Azure DevOps.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Déploiement d'applications sur des serveurs locaux ou dans le cloud principalement chez Azure.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Maintenance continue des applications, y compris la résolution des problèmes et la mise à jour des fonctionnalités.</li>
                  <li><i className="bi bi-check-circle-fill"></i>Application de bonnes pratiques de sécurité, telles que la validation des entrées utilisateur et la protection contre les attaques par injection SQL et XSS.</li>
                </ul>
              </div>

              
              <div className="col-lg-4 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                <img src="assets/img/features-1.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-2">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">

                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-2.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-3">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Pariatur</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.</li>
                </ul>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-3.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-4">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Nostrum</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-4.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-5">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Adipiscing</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-5.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-6">
            <div className="row gy-4">
              <div className="col-lg-8 order-2 order-lg-1">
                <h3>Reprehit</h3>
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum
                </p>
                <p className="fst-italic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <ul>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle-fill"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>
              </div>
              <div className="col-lg-4 order-1 order-lg-2 text-center">
                <img src="assets/img/features-6.svg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </>
  )
}

