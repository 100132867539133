import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router , Routes, Route} from 'react-router-dom' ;
import App  from './components/PorteFolio/App';
import Réalisations  from './components/PorteFolio/Réalisations';
import Moi  from './components/PorteFolio/Moi';
import Footer  from './components/PorteFolio/Footer';
import Compétences from './components/PorteFolio/Back-End';
import Frontend from './components/PorteFolio/Front-End';
import Test from './components/PorteFolio/Test';
import Ml from './components/PorteFolio/Ml';
import Devops from './components/PorteFolio/Devops';
import Cloud from './components/PorteFolio/Cloud';
import Architecte from './components/PorteFolio/Architecte';
import Gp from './components/PorteFolio/Gp';
import Af from './components/PorteFolio/Af';
import Optimisation from './components/PorteFolio/Op';
import Web3D from './components/PorteFolio/Web';
import Data from './components/PorteFolio/Data';
import Innovation from './components/PorteFolio/INNOV';
import RO from './components/PorteFolio/RO';
import SC from './components/PorteFolio/SC';
import IOT from './components/PorteFolio/IOT';
import Blockchain from './components/PorteFolio/Blockchain';
import Modélisation from './components/PorteFolio/Modélisation';

import Contact from './components/PorteFolio/Contact';
import Compétence from './components/PorteFolio/Compétences';
import Valeurs from './components/PorteFolio/Valeurs';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
         <Router>
        
        <Routes> 
        <Route path='/' element={<App/>} />
        <Route path='/Qui_Suis_Je' element={<Moi/>} />
        <Route path='/Compétences' element={<Compétence/>} />
        <Route path='/Réalisations' element={<Réalisations/>} />
        <Route path='/Valeurs'  element={< Valeurs />} />
        <Route path='/Contact'  element={< Contact />} />
        <Route path='/Back-end' element={<Compétences/>} />
        <Route path='/Front-end' element={<Frontend/>} />
        <Route path='/Test' element={<Test/>} />
        <Route path='/Devops' element={<Devops/>} />
        <Route path='/Cloud' element={<Cloud/>} />
        <Route path='/Architecte' element={<Architecte/>} />
        <Route path='/Apprentissage-automatique' element={<Ml/>} />
        <Route path='/Gestion-De-Projet' element={<Gp/>} />
        <Route path='/Analyse-Fonctionnelle' element={<Af/>} />
        <Route path='/Optimisation' element={<Optimisation/>} />
        <Route path='/Web3D' element={<Web3D/>} />
        <Route path='/Datavisualisation' element={<Data/>} />
        <Route path='/Innovation' element={<Innovation/>} />
        <Route path='/Recherche-Opérationnelle' element={<RO/>} />
        <Route path='/Système-complexe' element={<SC/>} />
        <Route path='/IOT' element={<IOT/>} />
        <Route path='/Blockchain' element={<Blockchain/>} />
        <Route path='/Modélisation' element={<Modélisation/>} />
        

        </Routes>
      </Router>
          <Footer/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
