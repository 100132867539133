import { Link } from "react-router-dom";

export default function Banner(){

    return(
        <>
        
        <section id="hero-animated" className="hero-animated d-flex align-items-center">
        <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
          <img src="assets/img/hero-carousel/hero-carousel-3.svg" className="img-fluid animated" />
          <h2>Naviguons dans <span>mon  écosystème.</span></h2>
          <p>Ingénieur et développeur informatique au parcours atypique, Ulrich Sanga vous invite à découvrir son histoire, ses défis, ses réalisations, ses compétences et comment il travaille chaque jours avec hargne et détermination pour appartenir un jour  au cercle prestigieux des développeurs de classe mondiale.</p>
          <div className="d-flex"> <Link to={"/Qui_Suis_Je"}><a href="" className="btn-get-started scrollto">Qui Suis-Je?</a></Link>
            <a href="/" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Video</span></a>
          </div>
        </div>
      </section>
   
       
        </>

    );

}

